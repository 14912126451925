import React, { useState } from "react";
import InsertShape from "../InsertDesign/InsertShape";
import InsertEmoji from "../InsertDesign/InsertEmoji";
import InsertPictures from "../InsertDesign/InsertPictures";
import LayoutSizeDesign from "../LayoutDesigns/LayoutSizeDesign";
import TableProperties from "../InsertDesign/Table/TableProperties";
import LayoutMarginDesign from "../LayoutDesigns/LayoutMarginDesign";
import LayoutColumnDesign from "../LayoutDesigns/LayoutColumnDesign";
import InsertTableDesign from "../InsertDesign/Table/InsertTableDesign";
import LayoutOrientationDesign from "../LayoutDesigns/LayoutOrientationDesign";
import { Form } from "react-bootstrap";
import { BiCollapse, BiExpand } from "react-icons/bi";
import ReactToPrint from "react-to-print";

const Toolbar = ({
  setOrientation,
  componentRef,
  handleMarginChange,
  onSizeChange,
  onColumnChange,
  onShapeInsert,
  onInsertTable,
  setActiveTab,
  activeTab,
  tableProperties,
  updateTableProperties,
  addRow,
  addColumn,
  deleteRow,
  deleteColumn,
  onToggleHeader,
  onToggleFooter,
  onPrint,
  onConvertToPDF,
  onEmojiInsert,
  handleImageUpload,
  handleCellChange,
  selectedCell,
  toggleFullScreen,
  isFullScreen,
  loadFormData,
}) => {
  const [fontSize, setFontSize] = useState(11);
  const [show, setShow] = useState(false);
  const [bgColor, setBgColor] = useState("#ffffff");
  const [fontColor, setFontColor] = useState("#000000");
  const [fontFamily, setFontFamily] = useState("Calibri (Body)");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCommand = (command, value = null) => {
    document.execCommand(command, false, value);
  };

  const applyTextFormat = (format) => {
    if (format === "normal") {
      document.execCommand("formatBlock", false, "p");
    } else {
      document.execCommand("formatBlock", false, format);
    }
  };

  const handleFontSizeChange = (size) => {
    document.execCommand("fontSize", false, "7");
    const elements = document.getElementsByTagName("font");
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].size === "7") {
        elements[i].removeAttribute("size");
        elements[i].style.fontSize = `${size}px`;
      }
    }
  };

  const handleHyperlink = () => {
    let url = prompt("Enter the URL");

    if (url) {
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = `https://${url}`;
      }
      document.execCommand("createLink", false, url);

      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const anchorNode = selection.anchorNode;
        const parentElement = anchorNode?.parentElement;

        if (parentElement && parentElement.tagName === "A") {
          parentElement.setAttribute("target", "_blank");
          parentElement.style.cursor = "pointer";
          parentElement.addEventListener("click", (e) => {
            window.open(parentElement.href, "_blank");
          });
        }
      }
    }
  };

  const renderHomeToolbar = () => (
    <div className="border-bottom ps-2" style={{ background: "#f8f8f8" }}>
      <div className="hstack gap-2 toolbar text-nowrap blank overflow-auto">
        <i
          class="fa-solid fa-copy"
          title="copy"
          onClick={() => handleCommand("copy")}
        ></i>
        <div className="vr"></div>
        <i
          class="fa-solid fa-scissors"
          title="cut"
          onClick={() => handleCommand("cut")}
        ></i>
        <div className="vr"></div>
        <i
          className="fa fa-paste"
          title="paste"
          onClick={() => handleCommand("paste")}
        ></i>
        <div className="vr"></div>
        <Form.Select
          className="p-1 border-0 rounded-0 bg-transparent"
          style={{ fontSize: 12, width: 120 }}
          value={fontFamily}
          onChange={(e) => handleCommand("fontName", e.target.value)}
        >
          <option>Calibri (Body)</option>
          <option>Arial</option>
          <option>Times New Roman</option>
          <option>Verdana</option>
          <option>Georgia</option>
          <option>Roboto</option>
        </Form.Select>
        <div className="vr"></div>
        <Form.Select
          className="p-1 border-0 rounded-0 bg-transparent"
          value={fontSize}
          style={{ fontSize: 12, width: 50 }}
          onChange={(e) => {
            setFontSize(e.target.value);
            handleFontSizeChange(e.target.value);
          }}
        >
          {[...Array(45)].map((_, index) => {
            const size = index + 6;
            return (
              <option key={size} value={size}>
                {size}
              </option>
            );
          })}
        </Form.Select>
        <div className="vr"></div>
        <i
          class="fa-solid fa-bold"
          title="Bold"
          onClick={() => handleCommand("bold")}
        ></i>
        <div className="vr"></div>
        <i
          class="fa-solid fa-italic"
          title="Italic"
          onClick={() => handleCommand("italic")}
        ></i>
        <div className="vr"></div>
        <i
          class="fa-solid fa-underline"
          title="Underline"
          onClick={() => handleCommand("underline")}
        ></i>
        <div className="vr"></div>
        <div className="position-relative" title="Font Color">
          <i class="fa-solid fa-palette" title="Font Color"></i>
          <Form.Control
            className="position-absolute z-1 top-0 opacity-0"
            type="color"
            value={fontColor}
            onChange={(e) => handleCommand("foreColor", e.target.value)}
          />
        </div>
        <div className="vr"></div>
        <div className="position-relative" title="Background Color">
          <i class="fa-solid fa-paint-roller"></i>
          <Form.Control
            className="position-absolute z-1 top-0 opacity-0"
            type="color"
            value={bgColor}
            onChange={(e) => handleCommand("hiliteColor", e.target.value)}
          />
        </div>
        <div className="vr"></div>
        <i
          class="fa-solid fa-align-left"
          title="Align left"
          onClick={() => handleCommand("justifyLeft")}
        ></i>
        <div className="vr"></div>
        <i
          class="fa-solid fa-align-center"
          title="Align center"
          onClick={() => handleCommand("justifyCenter")}
        ></i>
        <div className="vr"></div>
        <i
          class="fa-solid fa-align-right"
          title="Align right"
          onClick={() => handleCommand("justifyRight")}
        ></i>
        <div className="vr"></div>
        <i
          class="fa-solid fa-align-justify"
          title="Justify"
          onClick={() => handleCommand("justifyFull")}
        ></i>
        <div className="vr"></div>
        <i title="Header" onClick={onToggleHeader}>
          <div> Header</div>
        </i>
        <div className="vr"></div>
        <i title="Footer" onClick={onToggleFooter}>
          <div> Footer</div>
        </i>
        <div className="vr"></div>
        <ReactToPrint
          trigger={() => (
            <i className="fa fa-print" title="Print" onClick={onPrint}></i>
          )}
          content={() => componentRef.current}
        />
        <div className="vr"></div>
        <i title="Convert To PDF" onClick={onConvertToPDF}>
          <div> Convert To PDF</div>
        </i>
        <div className="vr"></div>
        <i title="Hyperlink" onClick={handleHyperlink}>
          <div> Hyperlink</div>
        </i>
        <div className="vr"></div>
        <i title="Table Properties" onClick={handleShow}>
          <div> Table Properties</div>
        </i>
        <div className="vr"></div>
        <Form.Select
          className="p-1 border-0 rounded-0 bg-transparent"
          style={{ fontSize: 12, width: 150 }}
          onChange={(e) => applyTextFormat(e.target.value)}
        >
          <option value="normal">Normal</option>
          <option value="h1">H1</option>
          <option value="h2">H2</option>
          <option value="h3">H3</option>
          <option value="h4">H4</option>
          <option value="h5">H5</option>
          <option value="h6">H6</option>
        </Form.Select>
      </div>
    </div>
  );

  const renderInsertToolbar = () => (
    <div className="hstack gap-2 p-2 border-bottom">
      <button className="border position-relative">
        <InsertPictures handleImageUpload={handleImageUpload} />
      </button>
      <button className="border position-relative">
        <InsertTableDesign onInsertTable={onInsertTable} />
      </button>
      <button className="border position-relative">Chart</button>
      <button className="border position-relative">
        <InsertShape onShapeInsert={onShapeInsert} />
      </button>
      <button className="border position-relative">
        <InsertEmoji onEmojiInsert={onEmojiInsert} />
      </button>
    </div>
  );

  const renderLayoutToolbar = () => (
    <div className="hstack gap-2 p-2 border-bottom">
      <button className="border position-relative">
        <LayoutMarginDesign handleMarginChange={handleMarginChange} />
      </button>
      <button className="border position-relative">
        <LayoutOrientationDesign setOrientation={setOrientation} />
      </button>
      <button className="border position-relative">
        <LayoutSizeDesign onSelectSize={onSizeChange} />
      </button>
      <button className="border position-relative">
        <LayoutColumnDesign onSelectColumn={onColumnChange} />
      </button>
    </div>
  );

  const renderToolbar = () => {
    switch (activeTab) {
      case "Home":
        return renderHomeToolbar();
      case "Insert":
        return renderInsertToolbar();
      case "Layout":
        return renderLayoutToolbar();
      default:
        return renderHomeToolbar();
    }
  };

  return (
    <div className="position-sticky top-0 z-1">
      <div className=" hstack justify-content-between p-2 border-bottom ">
        <div className="hstack gap-2 ">
          <button
            className={`updateProfile ${
              activeTab === "Home" ? "" : "bg-white text-dark"
            }`}
            onClick={() => setActiveTab("Home")}
          >
            Home
          </button>
          <button
            className={`updateProfile ${
              activeTab === "Insert" ? "" : "bg-white text-dark"
            }`}
            onClick={() => setActiveTab("Insert")}
          >
            Insert
          </button>
          <button
            className={`updateProfile ${
              activeTab === "Layout" ? "" : "bg-white text-dark"
            }`}
            onClick={() => setActiveTab("Layout")}
          >
            Layout
          </button>
          <Form.Select
            onChange={(e) => loadFormData(e.target.value)}
            className={`updateProfile bg-white text-dark`}
          >
            <option>Select Report type</option>
            <option value="single">Single Field Report</option>
            <option value="group">Group Field Report</option>
          </Form.Select>
        </div>
        <button
          className="tabButtons fontSize12 border-0  "
          title={!isFullScreen ? "Full Mode" : "Original Mode"}
          onClick={toggleFullScreen}
        >
          {isFullScreen ? <BiCollapse /> : <BiExpand />}
        </button>
      </div>
      {renderToolbar()}
      <TableProperties
        show={show}
        handleClose={handleClose}
        tableProperties={tableProperties}
        updateTableProperties={updateTableProperties}
        addRow={addRow}
        addColumn={addColumn}
        deleteRow={deleteRow}
        deleteColumn={deleteColumn}
        handleCellChange={handleCellChange}
        selectedCell={selectedCell}
      />
    </div>
  );
};

export default Toolbar;
