import React, { useState, useRef } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

const ACRF = () => {
  const [pdfFile, setPdfFile] = useState(null);
  const circleStyle = { width: 25, height: 25 };
  const fileInputRef = useRef(null);
  const pdfContainerRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileURL = URL.createObjectURL(file);
      setPdfFile(fileURL);
    }
  };

  return (
    <Container fluid>
      <Row>
        {/* PDF Viewer Section */}
        <Form.Group
          as={Col}
          md={8}
          className="p-0 border-end position-relative"
          style={{ height: "calc(100vh - 156px)" }}
          ref={pdfContainerRef}
        >
          {pdfFile ? (
            <Worker
              workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`}
            >
              <Viewer fileUrl={pdfFile} />
            </Worker>
          ) : (
            <div
              className="hstack justify-content-center"
              style={{ height: "calc(100vh - 165px)" }}
            >
              <p>No PDF selected</p>
            </div>
          )}
        </Form.Group>

        {/* Toolbar Section */}
        <Form.Group
          as={Col}
          md={4}
          className="pt-2 overflow-auto"
          style={{ height: "calc(100vh - 156px)", backgroundColor: "#f4f4f4" }}
        >
          <button
            className="w-100 selectedMenu border p-1 fontSize12 position-relative mb-2"
            onClick={() => fileInputRef.current.click()}
          >
            Upload Document
          </button>

          <Form.Control
            type="file"
            accept=".pdf"
            ref={fileInputRef}
            className="d-none"
            onChange={handleFileChange}
          />
          {/* Shape Tool Options */}
          {pdfFile && (
            <Form.Group className="my-2">
              <Form.Label className="mb-1 fw-bold">Tools</Form.Label>
              <div className="toolbar">
                <button
                  className="border py-2 px-3"
                  title="Text Field"
                  data-element="textFieldButton"
                >
                  <svg width="20" height="20" viewBox="0 0 20 20">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.25 2.25H19.75V17.75H0.25V2.25ZM18.25 11.75V3.75H1.75V16.25H13.75L18.25 11.75ZM11.25 5.5V8H9.75V7H8V13H8.75V14.5H5.75V13H6.5V7H4.75V8H3.25V5.5H11.25Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  <span className="ms-1">Text Field</span>
                </button>
              </div>
            </Form.Group>
          )}
          <Form.Group className="my-2">
            <Form.Label className="mb-1 fw-bold">Font Color</Form.Label>
            <div className="hstack gap-2 flex-wrap">
              <div
                className="border cursor-pointer bg-primary"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-primary-subtle"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-secondary"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-secondary-subtle"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-success"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-success-subtle"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-danger"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-danger-subtle"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-warning"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-warning-subtle"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-info"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-info-subtle"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-light"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-light-subtle"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-dark"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-dark-subtle"
                style={circleStyle}
              ></div>
              <Form.Control
                type="color"
                className="p-0 border-0"
                defaultValue="#fff"
                style={circleStyle}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 fw-bold">Background Color</Form.Label>
            <div className="hstack gap-2 flex-wrap">
              <div
                className="border cursor-pointer bg-primary"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-primary-subtle"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-secondary"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-secondary-subtle"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-success"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-success-subtle"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-danger"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-danger-subtle"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-warning"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-warning-subtle"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-info"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-info-subtle"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-light"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-light-subtle"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-dark"
                style={circleStyle}
              ></div>
              <div
                className="border cursor-pointer bg-dark-subtle"
                style={circleStyle}
              ></div>
              <Form.Control
                type="color"
                className="p-0 border-0"
                defaultValue="#fff"
                style={circleStyle}
              />
            </div>
          </Form.Group>
          <button className="updateProfile">Save</button>
        </Form.Group>
      </Row>
    </Container>
  );
};

export default ACRF;
