import React, { useEffect, useRef, useState } from "react";
import "./print.css";
import moment from "moment";
import { Rnd } from "react-rnd";
import jsonData from "./data.json";
import { useParams } from "react-router";
import DynamicRuler from "./DynamicRuler";
import { Form, InputGroup, Table } from "react-bootstrap";
import { formReportServices } from "../../../Services/studyServices/formReportServices";

const EditorWritingPage = ({
  size,
  margin,
  tables,
  columns,
  imageSrc,
  formName,
  formType,
  siteName,
  formArray,
  shapeSize,
  visitName,
  showFooter,
  showHeader,
  orientation,
  subjectName,
  renderShape,
  setShapeSize,
  setActiveTab,
  isFullScreen,
  componentRef,
  imageToInsert,
  shapeToInsert,
  emojiToInsert,
  tableProperties,
  setSelectedCell,
  setSelectedTableIndex,
  getCellAlignmentStyles,
  handleLinePositionChange,
}) => {
  const editorRef = useRef(null);
  const { studyId } = useParams();
  const [headerTitle, setHeaderTitle] = useState("");
  const [headerTitleLength, setHeaderTitleLength] = useState(0);
  const [footerTitle, setFooterTitle] = useState("");
  const [reportData, setReportData] = useState([]);
  const [height, setHeight] = useState("");
  const [width, setWidth] = useState("");
  const [logo, setLogo] = useState("");
  const GeneratedDate = moment(new Date()).format("DD-MM-YYYY | HH:mm:ss");
  // const MAX_PAGE_HEIGHT = 1060;
  const [pages, setPages] = useState([0]);
  const pageRefs = useRef([]);

  const getReportData = () => {
    setReportData(jsonData?.data);
  };
  // const editorStyle = { width: "100%", minHeight: `${MAX_PAGE_HEIGHT}px` };
  const editorStyle = { width: "100%" };
  const marginStyle = {
    paddingTop: margin.top,
    paddingBottom: margin.bottom,
    paddingLeft: margin.left,
    paddingRight: margin.right,
  };
  const headermarginStyle = {
    paddingLeft: margin.left,
    paddingRight: margin.right,
  };

  const pageStyle = {
    width: orientation === "portrait" ? size.width : size.height,
    height: orientation === "portrait" ? size.height : size.width,
    marginBottom: margin.bottom,
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  };

  const columnData = {
    columnCount: typeof columns === "number" ? columns : 1,
    columnGap: "1em",
  };

  const contentStyle = {
    ...marginStyle,
    flexGrow: 1,
  };

  const insertImageAtCaret = (imageSrc) => {
    const img = document.createElement("img");
    img.src = imageSrc;
    img.style.maxWidth = "100%";
    img.style.maxHeight = "100%";

    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      range.insertNode(img);
      range.collapse(false);
    }
  };

  const getReportStudyMetadataData = async () => {
    let data = await formReportServices.getReportStudyMetadataData(studyId);
    let arrayData = data?.data;
    let headerData = arrayData?.find(
      (item) => item.type === "header"
    )?.headerData;
    setHeaderTitleLength(headerData?.length || 0);
    let footerData = arrayData?.find(
      (item) => item.type === "footer"
    )?.footerData;
    let logoData = arrayData?.find((item) => item.type === "logo");
    let headerDataArray = headerData?.map((item) => item.text).join(`<br />`);
    setHeaderTitle(headerDataArray || "");
    setFooterTitle(footerData || "");
    setHeight(logoData?.height || 60);
    setWidth(logoData?.width || 60);
    setLogo(
      logoData?.logo ||
        "https://gas-admin-bucket.s3.ap-south-1.amazonaws.com/1729008098534.png"
    );
  };

  // const handleContentInput = (item) => {
  //   const currentPage = pageRefs.current[item];

  //   if (currentPage && currentPage.scrollHeight > MAX_PAGE_HEIGHT) {
  //     if (item === pages.length - 1) {
  //       currentPage.contentEditable = true;
  //       setPages([...pages, pages.length]);
  //       setTimeout(() => {
  //         const nextPage = pageRefs.current[item + 1];
  //         if (nextPage) {
  //           nextPage.focus();
  //         }
  //       }, 0);
  //     }
  //   }
  // };

  useEffect(() => {
    if (imageToInsert) {
      insertImageAtCaret(imageToInsert);
    }
  }, [imageToInsert]);

  const insertEmojiAtCaret = (emoji) => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const emojiNode = document.createTextNode(emoji);
      range.insertNode(emojiNode);
      range.collapse(false);
    }
  };

  useEffect(() => {
    if (emojiToInsert) {
      insertEmojiAtCaret(emojiToInsert);
    }
  }, [emojiToInsert]);

  useEffect(() => {
    getReportStudyMetadataData();
  }, []);

  useEffect(() => {
    getReportData();
  }, []);

  function toDigitOrCharArray(input) {
    let str = input?.toString();
    if (!isNaN(input) && str?.length === 1) {
      return [0, Number(str)];
    }
    return str?.split("");
  }

  console.log("reportData>>>>>>>>>>", reportData);
 
  return (
    <>
      <DynamicRuler
        orientation={orientation}
        size={size}
        onLinePositionChange={handleLinePositionChange}
      />
      <div
        style={{
          height: isFullScreen ? "calc(100vh - 130px)" : "calc(100vh - 285px)",
          overflow: "auto",
        }}
      >
        {/* {reportData?.map((item, index) => ( */}
        <div ref={componentRef}>
        <div className="text-editor text-editor-container" ref={editorRef}>
          {reportData?.map((item, index) => (
            <div
              key={index}
              // onInput={() => handleContentInput(index)}
              ref={(el) => (pageRefs.current[index] = el)}
              contentEditable={true}
              style={{
                border: "1px solid #eee",
                borderRadius: 3,
                outline: "none",
                minHeight: "11in",
                ...editorStyle,
                ...pageStyle,
              }}
              suppressContentEditableWarning={true}
            >
              <div>
                {showHeader && (
                  <div
                    key={index}
                    className="header"
                    style={{
                      ...contentStyle,
                      borderBottom: "1px dashed #eee",
                    }}
                    contentEditable={true}
                  >
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="m-0 fw-bold fontSize12">
                          {headerTitle ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: headerTitle,
                              }}
                            />
                          ) : (
                            "Study Title & Details"
                          )}
                        </p>
                        <p className="m-0 fw-bold fontSize12">
                          Subject: {item.subject}
                        </p>
                        <p className="m-0 fw-bold fontSize12">
                          Generated On: {GeneratedDate}
                        </p>
                      </div>
                      <img
                        src={logo}
                        alt="Company Logo"
                        style={{ width: width, height: height }}
                      />
                    </div>
                  </div>
                )}
                <div
                  style={{ ...contentStyle, ...columnData }}
                  className="content_Id"
                >
                  <Table>
                    {showHeader && (
                      <thead>
                        <tr className="spacingHeader">
                          <th className="border-0">
                            <div className="t-head">&nbsp;</div>
                            <div className="t-head">&nbsp;</div>
                            <div className="t-head">&nbsp;</div>
                          </th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      <tr>
                        <td className="border-0 p-0">
                          <div className="content">
                            <div className="page">
                              {Object.keys(item)
                                .filter((key) => key.startsWith("form"))
                                .map((formKey, formIndex) => (
                                  <div key={formIndex}>
                                    <h4>{formKey}</h4>
                                    {item[formKey].map(
                                      (formField, fieldIndex) => (
                                        <div
                                          key={fieldIndex}
                                          style={{ marginLeft: "20px" }}
                                        >
                                          <p>Type: {formField.type}</p>
                                        </div>
                                      )
                                    )}
                                  </div>
                                ))}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    {showFooter && (
                      <tfoot>
                        <tr className="spacingHeader">
                          <td className="border-0 p-0">
                            <div className="t-foot">&nbsp;</div>
                            <div className="t-foot">&nbsp;</div>
                            <div className="t-foot">&nbsp;</div>
                          </td>
                        </tr>
                      </tfoot>
                    )}
                  </Table>
                </div>
                {showFooter && (
                  <div
                    className="footer bg-white text-center"
                    style={{
                      ...contentStyle,
                      borderTop: "1px dashed #eee",
                    }}
                  >
                    <div className="hstack justify-content-between">
                      {footerTitle?.title1 && (
                        <span contentEditable={true} className="text-start">
                          {footerTitle?.title1} <br />
                          {footerTitle?.title2}
                        </span>
                      )}
                      {!footerTitle && (
                        <span contentEditable={true}>Confidencials</span>
                      )}
                      <span style={{ pointerEvents: "none" }}>
                        Page : {index + 1} of {index.length}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        </div>
        {/* ))} */}
      </div>
    </>
  );
};

export default EditorWritingPage;
